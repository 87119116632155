import BusinessCard from '../BusinessCard/BusinessCard';
import { Product } from '../../models/product';
import s from './ProductDetail.module.scss';
import sg from '../../styles/global.module.scss';
import clsx from 'clsx';
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  isPlatform,
  useIonViewDidEnter,
} from '@ionic/react';
import { buildUrlsFromUploadedFile } from '../Inputs/InputFile/oneFile/helpers';
import { getProductTags } from '../TaggedText/getProductTags';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import MediaGallery from '../MediaGallery/MediaGallery';
import { transformToGenericFiles } from '../Inputs/InputFile/oneFile/helpers';
import MediaSwiper from '../MediaSwiper/MediaSwiper';
import ImageDynamic from '../ImageDynamic/ImageDynamic';
import { locationOutline, playCircleOutline } from 'ionicons/icons';
import { FileCategoryEnum } from '../../@shared/file';
import { Business } from '../../models/business';
import Spinner from '../Spinner/Spinner';
import { useHistory } from 'react-router';
import { getCategoryAndSubcategoriesLine } from '../ProductCard/helpers';
import { TaggedText } from '../TaggedText/TaggedText';
import { ExclusiveAccess } from './ExclusiveAccess';
import { useTypedSelector } from '../../redux/hooks';

export interface ProductDetailProps {
  product: Product;
  business: Business | undefined;
}

const ProductDetail: React.FC<ProductDetailProps> = ({ product, business }) => {
  const history = useHistory();
  const hideBusinessInfo = (history.location.state as any)?.hideBusinessInfo;

  const [initialSlide, setInitialSlide] = useState<number>();
  const [activeIndex, setActiveIndex] = useState<number>();

  const genericFiles = useMemo(
    () => transformToGenericFiles(product.uploadedFiles),
    [product.uploadedFiles]
  );

  const productTags = useMemo(() => getProductTags(product), [product]);

  const [cityName, setCityName] = useState('-');
  useEffect(() => {
    if (!business) return;
    const city = business.city?.name;
    if (city) {
      setCityName(`${city}, ${business.city?.country.name}`);
    } else {
      setCityName(business.cityCustom || 'Unknown');
    }
  }, [business]);

  const subtitle = useMemo(
    () => getCategoryAndSubcategoriesLine(product, true),
    [product]
  );

  const divRef = useRef<HTMLDivElement>(null);
  useIonViewDidEnter(() => {
    divRef.current?.scrollIntoView({ behavior: 'smooth' });
  });

  const user = useTypedSelector(s => s.auth.user);

  return (
    <div
      ref={divRef}
      // workaround for correct "scrollIntoView"
      style={{
        border: 'solid 1px var( --ion-background-color)',
        marginTop: '-10px',
        paddingBottom: '30px',
      }}
    >
      <p className={clsx(s.Title)}>{product.title}</p>
      <p className={clsx(s.Subtitle)}>{subtitle}</p>

      <MediaGallery
        genericFiles={genericFiles}
        initialSlide={initialSlide}
        setInitialSlide={setInitialSlide}
      />

      <div className={clsx(s.MediaContainer)}>
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <MediaSwiper
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            uploadedFiles={product.uploadedFiles}
            onSlideClick={(i: number) => setInitialSlide(i)}
          />
        </div>

        <IonGrid fixed>
          <IonRow>
            {product.uploadedFiles.map((uploadedFile, index) => (
              <IonCol
                size="1.2"
                key={index}
                onClick={() => setActiveIndex(index)}
                className={clsx(
                  isPlatform('desktop')
                    ? sg.Hover_Animation_Transparency
                    : undefined
                )}
                style={{ position: 'relative' }}
              >
                <ImageDynamic
                  urls={buildUrlsFromUploadedFile(uploadedFile)}
                  quality="low"
                  objectFit="cover"
                  className={clsx(
                    s.ImagePrev,
                    index === activeIndex ? s.ImagePrev__active : undefined
                  )}
                />

                {uploadedFile.fileCategory === FileCategoryEnum.VIDEO && (
                  <IonIcon
                    icon={playCircleOutline}
                    className={clsx(s.Play__button)}
                  />
                )}
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </div>

      {business ? (
        <BusinessCard
          hideBusinessInfo={hideBusinessInfo}
          rating={0}
          reviewsCount={0}
          description={business.category?.name || business.categoryCustom}
          business={business}
        />
      ) : (
        <div
          style={{
            height: '70px',
            width: '100%',
            position: 'relative',
          }}
        >
          <Spinner />
        </div>
      )}

      <div
        className={clsx(sg.Stack_Vertical, sg.Gap_20)}
        style={{ marginTop: '20px' }}
      >
        {productTags.map((tag, index) => (
          <TaggedText key={index} {...tag} />
        ))}

        <TaggedText
          key="location"
          title="Location"
          icon={locationOutline}
          value={cityName}
        />
      </div>

      {user?.hasExclusiveAccess && (
        <ExclusiveAccess
          contactInfoContextData={{
            isExclusiveAccessModal: true,
            businessId: product.business,
            product: {
              id: product.id,
              title: product.title,
              category: product.category,
            },
          }}
        />
      )}
    </div>
  );
};

export default ProductDetail;
